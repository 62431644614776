<template>
    <div>
      <b-modal
          id="filter"
          ok-only
          centered
          size="md"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">Filter</div></template>
          <div >
            <b-form-row>
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5">
                    <p style="font-weight: 600; margin:0px;">Instansi</p>
                  </b-col>:<b-col sm="6">
                      <model-select :options="options"
                          v-model="instansiID"
                          placeholder="select item">
                      </model-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-form-row>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="$bvModal.hide('filter')"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submitFilter()"
              >
                Terapkan
              </b-button>
            </div>
          </div>
      </b-modal>
      <b-row>
        <b-col cols="12">
          <!-- <b-card class="shadow" id="search-card"> -->
            <b-row>
            <b-col cols="11">
              <!-- <div class="form-row"> -->
                <div class="d-flex justify-content-between align-items-center shadow p-1 pr-3" style="background:white; border-radius:10px;">
                  <input
                    type="text"
                    class="form-control border-0"
                    v-model="search"
                    :placeholder="'Cari...'"
                    v-on:keyup.enter="searching()"
                  />
                  <b-button
                    size="sm"
                    style="width:80px;margin-right:10px;"
                    variant="primary"
                    @click="searching()"
                  >
                    <v-icon
                      style="color:white;"
                      small
                    >mdi-magnify</v-icon>
                    Cari
                  </b-button>
                </div>
                
              <!-- </div> -->
            </b-col>
            <b-col cols="1" class="d-flex align-items-center p-0">
              <b-button
                size="sm"
                variant="primary"
                @click="showFilter()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-filter</v-icon>
                Filter
              </b-button>
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Kelola Hak Akses Pengguna</span>
        </b-col>
      </b-row>
       <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Pengguna</th>
                  <th style="min-width: 50px" class="text-capitalize">Instansi</th>
                  <th style="min-width: 50px" class="text-capitalize">Informasi Dalam Wilayah</th>
                  <th style="min-width: 50px" class="text-capitalize">Informasi Luar Wilayah</th>
                  <th style="min-width: 50px" class="text-capitalize">Akses Persetujuan</th>
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in listHakAkses">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.nama_lengkap}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.masterKesbangpol ? item.masterKesbangpol.nama : ''}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-center
                      font-size-lg
                    ">
                    <b-form-checkbox v-if="!loading" @change="changeAccess(item)" v-model="item.hakAkses.akses_info_wilayah" switch></b-form-checkbox>
                    <b-spinner small v-if="loading" ></b-spinner>
                    </span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-center
                      font-size-lg
                    ">
                    <b-form-checkbox v-if="!loading" @change="changeAccess(item)" v-model="item.hakAkses.akses_info_luar" switch></b-form-checkbox>
                    <b-spinner small v-if="loading" ></b-spinner>
                    </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-center
                      font-size-lg
                    ">
                    <b-form-checkbox v-if="!loading" @change="changeAccess(item)" v-model="item.hakAkses.akses_persetujuan" switch></b-form-checkbox>
                    <b-spinner small v-if="loading" ></b-spinner>
                    </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="1"
                :per-page="20"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<style>
#search-card .card-body {
  padding:0px !important;
}
</style>
<script>
import Swal from "sweetalert2";
  import { ModelSelect } from 'vue-search-select'

export default {
  name: "Sengketa",
  components: {
      ModelSelect
  },
  data() {
    return {
      search: '',
      options: [],
      createMode: true,
      currentPage: 1,
      listHakAkses: [],
      form: {
        "sumber_laporan": '',
        "keterangan": '',
        "status_sengketa": 0,
        "tanggal_sengketa": '',
        "data_ormas_id": ''
      },
      id: null,
      instansiID: null,
      listOrmas: [],
      filter: {
        status_sengketa: null,
        tanggal_sengketa: ''
      },
      filterStatus: false,
      loading: false,
      query: {
        search: '',
        instansi: ''
      },
    }
  },
  mounted(){
    this.fetchData()
    this.getOrmasList()
  }, 
  methods: {
    getOrmasList(){
      this.$store
        .dispatch("instansiList")
        .then((res) => {
           this.$nextTick(() => {
            this.listOrmas = res.data
            for(var i = 0; i < res.data.length; i++){
              this.options.push({value: res.data[i].id , text: res.data[i].nama})
            }
          })
          })
    },
    changeAccess(val){
      this.loading = true

      delete val.hakAkses.id 
      delete val.hakAkses.profil_user_id
      this.$store
      .dispatch("submitHakAkses", [val.hakAkses, val.id, val.sys_user_id])
      // .dispatch("getBankAccountOrganization", 4)
      .then(() => {
        Swal.fire({
          title: "Hak akses berhasil diubah",
          icon: "success",
            // text: "Silahkan periksa no skt dan password anda kembali",
          showCancelButton: false,
          confirmButtonColor: "#063A69",
          confirmButtonText: "Tutup",
        }).then(() => {
        })
        this.loading = false
        this.fetchData()
      }).catch(() =>{
        Swal.fire({
          title: "Hak akses gagal diubah",
          icon: "warning",
            // text: "Silahkan periksa no skt dan password anda kembali",
          showCancelButton: false,
          confirmButtonColor: "#063A69",
          confirmButtonText: "Tutup",
        }).then(() => {
        })
        this.loading = false
        this.fetchData()
      })
    },
    showFilter(){
      this.filterStatus = false
      this.$bvModal.show('filter')
    },
    submitFilter(){
      this.query.instansi = this.instansiID
      this.fetchData()
      this.$bvModal.hide('filter')
    },
    searching(){
      this.instansiID = null
      this.query.instansi = ''
      this.query.search = this.search

      if (this.query.search.length > 0 && this.query.search.length < 3) return;

      this.fetchData()
    },
    fetchData(){
      this.$store
      .dispatch("listHakAkses", this.query).then((res) => {
        for(var i = 0; i < res.data.length; i++){
          if(!res.data[i].hakAkses){
            res.data[i].hakAkses ={
              akses_info_wilayah: false,
              akses_info_luar: false,
              akses_persetujuan: false
            }
          }
        }
        this.listHakAkses = res.data
      })
    },
  },
  watch: {
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.searching()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  }
}
</script>